<template>
  <el-dialog
    id="FindOrgWithTreeDialog"
    ref="dialogWrapper"
    :title="title"
    :visible.sync="dialogShow"
    modal
    center
    :close-on-click-modal="false"
    width="90%"
    close-on-press-escape
    class="my-dialog abow_dialog"
    append-to-body
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @closed="onClosed"
    @keydown.enter.native="onFormEnter"
    destroy-on-close
  >
    <el-container>
      <div ref="flex_container" class="flex_container" v-loading="loading" :element-loading-text="loadingText">
        <splitpanes class="default-theme" vertical style="height: 100%">
          <pane min-size="20" max-size="80" ref="pane1">
            <el-header class="header3" height="48"
              ><el-row type="flex" justify="start">
                <div>
                  <el-input
                    :placeholder="$t('Пошук')"
                    style="width:220px;"
                    prefix-icon="el-icon-search"
                    clearable
                    v-model="filterString"
                    @input="onFilterStringChange"
                    ref="filter"
                    v-mask="mask"
                    @keydown.esc.native="resetFilter($event, this)"
                    @keydown.enter.native="runFilter($event, this)"
                  />
                </div>
                <div style="line-height:48px;display:flex;align-items:center;padding-left:16px;">
                  <el-dropdown trigger="click" @command="handleClick" v-if="isSmallWidth">
                    <span class="el-dropdown-link">
                      <TIButton :label="$t('Шукати за') + ': ' + searchBy_" dropdown="true" />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="find-by-name"
                        ><span :class="{ active: searchBy == 'name' }">{{ $t("Назвою") }}</span></el-dropdown-item
                      >
                      <el-dropdown-item command="find-by-address"
                        ><span :class="{ active: searchBy == 'address' }">{{ $t("Адресою") }}</span></el-dropdown-item
                      >
                      <el-dropdown-item command="find-by-axioma-id"
                        ><span :class="{ active: searchBy == 'axiomaId' }">{{ $t("Axioma ID") }}</span></el-dropdown-item
                      >
                      <el-dropdown-item command="find-by-okpo"
                        ><span :class="{ active: searchBy == 'okpo' }">{{ $t("ЄДРПОУ") }}</span></el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div v-else>
                    <span style="padding-right:8px;">{{ $t("Шукати за") }}</span>
                    <el-radio-group v-model="searchBy" size="small">
                      <el-radio-button label="name">{{ $t("Назвою") }}</el-radio-button>
                      <el-radio-button label="address">{{ $t("Адресою") }}</el-radio-button>
                      <el-radio-button label="axiomaId">{{ $t("Axioma ID") }}</el-radio-button>
                      <el-radio-button label="okpo">{{ $t("ЄДРПОУ") }}</el-radio-button>
                    </el-radio-group>
                  </div>
                  <el-dropdown trigger="click" @command="handleClick" v-if="isSmallWidth">
                    <span class="el-dropdown-link">
                      <TIButton :label="$t('Каталог') + ': ' + lang_" dropdown="true" />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="find-by-ua"><span :class="{ active: lang == 'ua' }">UA</span></el-dropdown-item>
                      <el-dropdown-item command="find-by-en"><span :class="{ active: lang == 'en' }">EN</span></el-dropdown-item>
                      <!-- <el-dropdown-item command="find-by-ru"><span :class="{ active: lang == 'ru' }">RU</span></el-dropdown-item> -->
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div v-else>
                    <span style="padding-left:16px;padding-right:8px;">{{ $t("Каталог") }}</span>
                    <el-radio-group v-model="lang" size="small">
                      <el-radio-button label="en">{{ $t("EN") }}</el-radio-button>
                      <el-radio-button label="ua">{{ $t("UA") }}</el-radio-button>
                      <!-- <el-radio-button label="ru">{{ $t("RU") }}</el-radio-button> -->
                    </el-radio-group>
                  </div>
                  <el-dropdown trigger="click" @command="handleClick" :key="menuKey">
                    <span class="el-dropdown-link">
                      <TIButton icon="import_blue" :label="$t('Імпорт')" dropdown="true" />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="import-org-from-xlsx"
                        ><img class="svg" :src="require('../../assets/icons/import_blue.svg')" />{{ $t("із Excel (.xlsx)") }}</el-dropdown-item
                      >
                      <el-dropdown-item command="import-org-from-json"
                        ><img class="svg" :src="require('../../assets/icons/import_blue.svg')" />{{ $t("із JSON") }}</el-dropdown-item
                      >
                      <el-dropdown-item divided command="import-org-from-contract"
                        ><img class="svg" :src="require('../../assets/icons/import_blue.svg')" />{{ $t("із договору") }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </el-row>
            </el-header>
            <div>
              <ag-grid-vue
                ref="findOrgTable"
                id="findOrgTable"
                style="width: 100%; height: 100%;"
                class="ag-theme-alpine"
                rowSelection="multiple"
                :defaultColDef="defaultColDef"
                :columnDefs="columns"
                :rowData="orgs"
                :treeData="true"
                :headerHeight="32"
                :gridOptions="gridOptions1"
                :groupDefaultExpanded="0"
                :overlayNoRowsTemplate="noRowsToShowTemplate"
                :frameworkComponents="frameworkComponents1"
                :getDataPath="getDataPath"
                @selection-changed="onGridSelectionChanged"
                @rowDoubleClicked="onGridDoubleClick1"
                :modules="modules"
                :autoGroupColumnDef="autoGroupColumnDef1"
                @gridReady="onGridReady1"
                @sortChanged="onGridColumnsChange1"
                @columnResized="onGridColumnsChange1"
                @columnMoved="onGridColumnsChange1"
                @displayedColumnsChanged="onGridColumnsChange1"
                :localeTextFunc="gridLocale"
                :getContextMenuItems="contextMenuItems1"
                :enableBrowserTooltips="true"
                :sideBar="sideBar"
                :pivotMode="false"
              >
              </ag-grid-vue>
            </div>
          </pane>
          <pane min-size="20" max-size="80" ref="pane2">
            <div style="line-height:54px;display:flex;align-items:center;padding:8px 0px;background-color:white;">
              <TIButton icon="plus_green" @onClick="onAdd" :disabled="!selectedRows1" />
              <TIButton icon="delete_red" @onClick="onDel" :disabled="!selectedRows2 || !selectedRows2.length" />
            </div>
            <div>
              <ag-grid-vue
                ref="findOrgTable2"
                id="findOrgTable2"
                style="width: 100%; height: 100%;"
                class="ag-theme-alpine"
                rowSelection="multiple"
                treeData="true"
                :groupDefaultExpanded="-1"
                :defaultColDef="defaultColDef"
                :columnDefs="columns2"
                :headerHeight="32"
                :rowData="selectedOrgs"
                :gridOptions="gridOptions2"
                :overlayNoRowsTemplate="noRowsToShowTemplate"
                @selection-changed="onGridSelectionChanged2"
                @rowDoubleClicked="onGridDoubleClick2"
                :modules="modules"
                :getDataPath="getDataPath"
                :autoGroupColumnDef="autoGroupColumnDef"
                @gridReady="onGridReady2"
                @sortChanged="onGridColumnsChange2"
                @columnResized="onGridColumnsChange2"
                @columnMoved="onGridColumnsChange2"
                @displayedColumnsChanged="onGridColumnsChange2"
                :localeTextFunc="gridLocale"
                :frameworkComponents="frameworkComponents2"
                :sideBar="sideBar"
                :pivotMode="false"
              >
              </ag-grid-vue>
            </div>
          </pane>
        </splitpanes>
      </div>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" :disabled="loading" type="success" @click="onCommit" class="apply-button">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
    <SelectFile @commit="orgImportData" bus-event="find.org.with.tree.forms.select.file" />
    <SelectFileForImportOrgFromXLS @commit="orgImportData" bus-event="find.org.with.tree.forms.select.xls.file" />
    <SelectContract @commit="onContractSelectData" bus-event="select.contract" />
  </el-dialog>
</template>

<script>
import Vue from "vue";
import { bus } from "@/main";
import { _ } from "vue-underscore";
import { AgGridVue } from "@ag-grid-community/vue";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import Icon from "@/components/IconPack";
import TIButton from "@/components/ToolbarIconButton";
import TSeparator from "@/components/ToolbarSeparator";
import GridTotalBottomRow from "@/components/GridTotalBottomRow.js";
// import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
// import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import GridCellWithIcons from "@/components/GridCellWithIcons.js";

function parseError(error) {
  if (typeof error == "string") {
    return error;
  } else if (Array.isArray(error)) {
    return error.join("; ");
  } else return error;
}

export default {
  name: "FindOrgWithTree",
  components: {
    AgGridVue,
    Splitpanes,
    Pane,
    Icon,
    TIButton,
    TSeparator,
    SelectFile: () => import("@/components/forms/SelectFile"),
    SelectFileForImportOrgFromXLS: () => import("@/components/forms/SelectFileForImportOrgFromXLS"),
    SelectContract: () => import("@/components/forms/contracts/SelectContract"),
  },
  data() {
    var that = this;
    return {
      lang: "ua",
      menuKey: 0,
      searchBy: "name",
      dialogShow: false,
      dialogHeight: 0,
      paneWidth: 750,
      resizeObserver: null,
      resizeObserverWidth: null,
      title: "Пошук",
      target: null,
      useLocalOrAxioma: true,
      model: {},
      initModel: {},
      filterString: "",
      menu: null,
      selectedRows1: null,
      selectedRows2: null,
      selectedOrgs: [],
      keyUpTimer: null,
      pinnedBottomRowData: null,
      modules: AllModules,
      frameworkComponents1: { GridCellWithIcons, GridTotalBottomRow },
      frameworkComponents2: { GridCellWithIcons, GridTotalBottomRow },
      getDataPath: (data) => {
        return data.path;
      },
      noRowsToShowTemplate: `<div><img src="${require("../../assets/icons/no_data.svg")}"></div>`,
      gridApi1: null,
      gridApi2: null,
      gridColumnApi1: null,
      gridColumnApi2: null,
      loadingWaitStatusText: null,
      gridOptions1: {
        //suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data.axiomaId;
        },
        immutableData: true,
        rowHeight: 32,
        getRowStyle: function(params) {
          if (that.selectedOrgs.find((item) => item.axiomaId == params.data.axiomaId)) return { "font-weight": "bold" };
          else return null;
        },
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
            {
              statusPanel: "GridTotalBottomRow",
              align: "left",
              items: [
                { id: 1, label: $tt("grid.orgs.okpo.count"), value: (i) => this.filteredClientsCount(this.orgs, (item) => item.okpo) },
                { id: 1, label: $tt("grid.orgs.normal.count"), value: (i) => this.filteredClientsCount(this.orgs, (item) => !item.okpo) },
              ],
            },
          ],
        },
      },
      gridOptions2: {
        getRowNodeId: function(data) {
          return data.axiomaId;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
            {
              statusPanel: "GridTotalBottomRow",
              align: "left",
              items: [
                { id: 1, label: $tt("grid.orgs.okpo.count"), value: (i) => this.filteredClientsCount(this.selectedOrgs, (item) => item.okpo) },
                { id: 1, label: $tt("grid.orgs.normal.count"), value: (i) => this.filteredClientsCount(this.selectedOrgs, (item) => !item.okpo) },
              ],
            },
          ],
        },
      },
      sideBar: {
        toolPanels: ["columns", "filters"],
        position: "left",
        hiddenByDefault: false,
      },
      defaultColDef: {
        suppressNavigable: true,
        cellClass: "no-border",
        resizable: true,
        sortable: true,
        tooltipValueGetter: function(params) {
          return params.value;
        },
        floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
        suppressMenu: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      autoGroupColumnDef1: {
        headerName: $tt("Назва"),
        headerTooltip: $tt("Назва"),
        flex: 2,
        sort: "asc",
        field: "name",
        cellRendererParams: {
          suppressCount: true,
          checkbox: true,
        },
        // valueGetter: function(params) {
        //   return params.data.name;
        // },
        // comparator: function(valueA, valueB, nodeA, nodeB, isInverted) {
        //   if (!nodeA || !nodeA.data) return 0;
        //   if (!nodeB || !nodeB.data) return 0;
        //   if (nodeA.data.okpo != "" && nodeB.data.okpo == "") return -1;
        //   if (nodeA.data.okpo == "" && nodeB.data.okpo != "") return 1;
        //   return valueA > valueB ? 1 : -1;
        // },
        // comparator: function(valueA, valueB, nodeA, nodeB, isInverted) {
        //   if (!nodeA || !nodeA.data) return 0;
        //   if (!nodeB || !nodeB.data) return 0;
        //   if ((nodeA.allChildrenCount == 0 || nodeA.allChildrenCount == null) && nodeB.allChildrenCount > 0) return 1 * (isInverted ? -1 : 1);
        //   if ((nodeB.allChildrenCount == 0 || nodeB.allChildrenCount == null) && nodeA.allChildrenCount > 0) return -1 * (isInverted ? -1 : 1);
        //   return valueA > valueB ? 1 : -1;
        // },
        comparator: function(valueA, valueB, nodeA, nodeB, isInverted) {
          if (!nodeA || !nodeA.data) return 0;
          if (!nodeB || !nodeB.data) return 0;
          if (nodeA.data.okpo != "" && nodeB.data.okpo == "") return -1 * (isInverted ? -1 : 1);
          if (nodeA.data.okpo == "" && nodeB.data.okpo != "") return 1 * (isInverted ? -1 : 1);
          if (nodeA.data.childCount > 0 && nodeB.data.childCount == 0) return -1 * (isInverted ? -1 : 1);
          if (nodeA.data.childCount == 0 && nodeB.data.childCount > 0) return 1 * (isInverted ? -1 : 1);
          return (valueA > valueB ? 1 : -1) * (isInverted ? -1 : 1);
        },
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
        cellRenderer: "GridCellWithIcons",
        cellRendererStyle: function(params) {
          var padding = (params.data.path.length - 1) * 17;
          if (params.data.childCount == 0) padding += 18;
          return "padding-left:" + padding + "px";
        },
        tooltipValueGetter: function(params) {
          return params.value;
        },
        cellIcons: [
          {
            icon: function(params) {
              if (params.node.expanded) return "arrow-down";
              else return "arrow-right";
            },
            type: "el-input__icon w0 el-icon-",
            color: "black",
            style: function(params) {
              return { "font-weight": "bold" };
            },
            iconClass: "icon-action",
            title: $tt("Статус"),
            onClick: (params) => {
              this.loadChildrenOrgs(params.data.axiomaId);
            },
            show: (params) => {
              return params.data.childCount > 0;
            },
          },
        ],
      },
      autoGroupColumnDef: {
        headerName: $tt("Назва"),
        headerTooltip: $tt("Назва"),
        flex: 2,
        field: "name",
        cellRendererParams: {
          suppressCount: true,
        },
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
        // valueGetter: function(params) {
        //   return params.data.name;
        // },
        tooltipValueGetter: function(params) {
          return params.value;
        },
        // comparator: function(valueA, valueB, nodeA, nodeB, isInverted) {
        //   if (!nodeA || !nodeA.data) return 0;
        //   if (!nodeB || !nodeB.data) return 0;
        //   if (nodeA.data.okpo != "" && nodeB.data.okpo == "") return -1;
        //   if (nodeA.data.okpo == "" && nodeB.data.okpo != "") return 1;
        //   return valueA > valueB ? 1 : -1;
        // },
        comparator: function(valueA, valueB, nodeA, nodeB, isInverted) {
          if (!nodeA || !nodeA.data) return 0;
          if (!nodeB || !nodeB.data) return 0;
          if ((nodeA.allChildrenCount == 0 || nodeA.allChildrenCount == null) && nodeB.allChildrenCount > 0) return 1 * (isInverted ? -1 : 1);
          if ((nodeB.allChildrenCount == 0 || nodeB.allChildrenCount == null) && nodeA.allChildrenCount > 0) return -1 * (isInverted ? -1 : 1);
          return valueA > valueB ? 1 : -1;
        },
        sort: "asc",
      },
      columns: [
        {
          headerName: $tt("Адреса"),
          field: "address",
          flex: 1,
        },
        {
          headerName: "AxiomaID",
          field: "axiomaId",
          width: "130px",
          type: "rightAligned",
        },
        {
          headerName: $tt("ЄДРПОУ"),
          field: "okpo",
          width: "130px",
          type: "rightAligned",
        },
        {
          headerName: $tt("Юридична особа"),
          field: "isEntity",
          width: "75px",
          valueGetter: (data) => {
            return data && data.data && data.data.okpo ? $tt("Так") : $tt("Ні");
          },
        },
        {
          headerName: $tt("Статус"),
          field: "status",
          width: "75px",
          valueGetter: (data) => {
            return data && data.data && data.data.status ? $tt("Активний") : "-";
          },
        },
        {
          headerName: $tt("Категорія"),
          field: "srcCategory",
          width: "50px",
          filter: "agSetColumnFilter",
        },
      ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
      columns2: [
        {
          headerName: $tt("Адреса"),
          field: "address",
          flex: 1,
        },
        {
          headerName: "AxiomaID",
          field: "axiomaId",
          width: "130px",
          type: "rightAligned",
        },
        {
          headerName: $tt("ЄДРПОУ"),
          field: "okpo",
          width: "130px",
        },
        {
          headerName: $tt("Юридична особа"),
          field: "isEntity",
          width: "75px",
          valueGetter: (data) => {
            return data && data.data && data.data.okpo ? $tt("Так") : $tt("Ні");
          },
          filter: "agSetColumnFilter",
        },
        {
          headerName: $tt("Статус"),
          field: "status",
          width: "75px",
          valueGetter: (data) => {
            return data && data.data && data.data.status ? $tt("Активний") : "-";
          },
          filter: "agSetColumnFilter",
        },
        {
          headerName: $tt("Категорія"),
          field: "srcCategory",
          width: "50px",
          filter: "agSetColumnFilter",
        },
      ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
    };
  },
  created() {
    bus.$on("forms.orgsWithTree.find.show", (data) => {
      if (!this.dialogShow) {
        this.title = (data && data.title) || this.title;
        this.target = (data && data.target) || "unknown";
        this.operation = (data && data.operation) || "create";
        this.initModel = _.extend({}, data ? data.initModel : {});
        this.$store.dispatch("orgs/init");
        this.filterString = "";

        this.selectedOrgs = data && data.selectedOrgs && Array.isArray(data.selectedOrgs) ? data.selectedOrgs : [];
        this.dialogShow = true;
      }
    });
    bus.$on("forms.orgsWithTree.find.hide", () => {
      this.dialogShow = false;
    });
  },

  computed: {
    mask() {
      switch (this.searchBy) {
        case "name":
          return undefined;
        case "address":
          return undefined;
        case "axiomaId":
          return "###########";
        case "okpo":
          return "###########";
      }
    },
    filteredClientsCount() {
      return function(clients, filterFunc) {
        if (clients) {
          return this.uniqueFilter(clients, function(item) {
            return function(val) {
              return item.axiomaId == val.axiomaId;
            };
          }).filter(filterFunc).length;
        } else return 0;
      };
    },
    orgs: {
      get() {
        return this.$store.getters["orgs/store"];
      },
      set(data) {
        this.$store.dispatch("orgs/addCustomOrgs", data);
      },
    },
    tree() {
      return this.$store.getters["orgs/tree"];
    },
    loading() {
      return this.$store.getters["orgs/wait"]() || this.$store.getters["orgs/treeWait"]();
    },
    loadingText() {
      var result = this.$store.getters["orgs/waitStatus"]() + " " + this.$store.getters["orgs/treeWaitStatus"]();
      result = this.loadingWaitStatusText ? this.loadingWaitStatusText : result;
      return result;
    },
    childrens() {
      return this.$store.getters["orgs/childrens"];
    },
    parents() {
      return this.$store.getters["orgs/parents"];
    },
    isSmallWidth() {
      return this.paneWidth < 970;
    },
    searchBy_() {
      switch (this.searchBy) {
        case "name":
          return $tt("Назвою");
        case "address":
          return $tt("Адресою");
        case "axiomaId":
          return $tt("Axioma ID");
        case "okpo":
          return $tt("ЄДРПОУ");
      }
    },
    lang_() {
      return this.lang.toUpperCase();
    },
  },
  watch: {
    dialogHeight(value) {
      var containerHeight = value - 48 - 72;
      this.$refs.flex_container.style.height = `${containerHeight}px`;
      this.$refs.flex_container.style.width = `100%`;
      this.$refs.flex_container.children[0].children[0].children[1].style.height = `${containerHeight - 48}px`;
      this.$refs.flex_container.children[0].children[2].children[1].style.height = `${containerHeight - 48}px`;
    },
    searchBy(value) {
      //this.onFilterStringChange(this.filterString, 10);
      this.resetFilter();
      this.$store.dispatch("main/updateUserSettings", { findOrgSearchBy: value });
    },
    orgs() {
      this.pinnedBottomRowData = { name: $tt("Рядків знайдено: {len}", { len: this.orgs.length }) };
    },
    lang(value) {
      this.resetFilter();
      this.$store.dispatch("main/updateUserSettings", { userSettings: { defaultCatalogLang: value } });
    },
  },
  beforeMount() {
    // this.frameworkComponents1 = { GridCellWithIcons, GridTotalBottomRow };
    // this.frameworkComponents2 = { GridCellWithIcons, GridTotalBottomRow };
  },
  mounted() {
    this.searchBy = this.$store.getters["main/userSettings"]("findOrgSearchBy") || "name";
    this.lang = this.$store.getters["main/userSettings"]("userSettings").defaultCatalogLang;
  },
  methods: {
    contextMenuItems1(params) {
      var contextMenu = [];
      var that = this;
      if (params.node && params.node.data && params.node.data.axiomaId) {
        contextMenu.push(
          {
            name: $tt("Додати рядок без підлеглих"),
            action() {
              that.runQueryTreeSingle(params.node.data);
            },
          },
          {
            name: $tt("Додати виділені рядки без підлеглих"),
            action() {
              that.runQueryTreeMulty();
            },
            disabled: !this.selectedRows1 || !this.selectedRows1.length,
          }
        );
      }
      contextMenu.push("copy");
      contextMenu.push("export");
      return contextMenu;
    },
    onGridReady1() {
      this.gridApi1 = this.gridOptions1.api;
      this.gridColumnApi1 = this.gridOptions1.columnApi;
      var state = this.$store.getters["main/userSettings"]("findOrgWithTreeGridColumnState1");
      if (state) this.gridColumnApi1.setColumnState(state);
      this.checkGridSettingsToChanges(this, $tt("Структури"), "findOrgWithTreeGridColumnState1", this.columns);
    },
    onGridReady2() {
      this.gridApi2 = this.gridOptions2.api;
      this.gridColumnApi2 = this.gridOptions2.columnApi;
      var state = this.$store.getters["main/userSettings"]("findOrgWithTreeGridColumnState2");
      if (state) this.gridColumnApi2.setColumnState(state);
    },
    loadChildrenOrgs(id) {
      var that = this;
      var parentNode = that.gridOptions1.api.getRowNode(id);
      if (parentNode.expanded) {
        parentNode.setExpanded(false);
        that.gridOptions1.api.refreshCells({ rowNodes: [parentNode], force: true });
      } else {
        if (parentNode.data.loadedChidrens) {
          parentNode.setExpanded(true);
          that.gridOptions1.api.refreshCells({ rowNodes: [parentNode], force: true });
        } else {
          this.$store
            .dispatch("orgs/getChildrens", { id: id, lang: this.lang }, 0)
            .then(() => {
              that.orgs = that.childrens.map((item) => {
                item.path = parentNode.data.path.concat(item.path);
                return item;
              });
              that.$nextTick(() => {
                parentNode.setExpanded(true);
                that.gridOptions1.api.refreshCells({ rowNodes: [parentNode], force: true });
              });
            })
            .catch((err) => {
              globalErrorMessage($tt("Помилка!"), err);
            });
        }
      }
    },
    observeHeight() {
      var that = this;
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(function() {
          if (that.$refs.dialogWrapper && that.$refs.dialogWrapper.$el) that.dialogHeight = that.$refs.dialogWrapper.$el.children[0].clientHeight;
        });
        this.resizeObserver.observe(this.$el.children[0]);
      }
    },
    observeWidth() {
      var that = this;
      if (!this.resizeObserverWidth) {
        if (that.$refs.pane1) {
          this.resizeObserverWidth = new ResizeObserver(function() {
            if (that.$refs.pane1 && that.$refs.pane1.$el) that.paneWidth = that.$refs.pane1.$el.clientWidth;
          });
          if (this.$refs.pane1) this.resizeObserverWidth.observe(this.$refs.pane1.$el);
        }
      }
    },
    unobserveHeight() {
      this.resizeObserver && this.resizeObserver.unobserve(this.$el.children[0]);
      this.resizeObserver = null;
      this.dialogHeight = 0;
    },
    unobserveWidth() {
      this.resizeObserverWidth && this.$refs.pane1 && this.resizeObserverWidth.unobserve(this.$refs.pane1.$el);
      this.resizeObserverWidth = null;
      this.paneWidth = 750;
    },
    resetFilter(event) {
      if (this.filterString != "") {
        event && event.stopPropagation();
        if (this.loading) {
          this.$store.dispatch("main/cancelAllRequests");
        } else this.$refs.filter.clear();
      }
    },
    runFilter(event) {
      if (this.filterString != "") {
        event && event.stopPropagation();
        this.runQueryFilter(this.filterString);
      }
    },
    onOpen() {
      this.observeHeight();
      this.menuKey = this.menuKey + 1;
    },
    onOpened() {
      this.$refs.filter.focus();
      this.observeWidth();
      this.menuKey = this.menuKey + 1;
    },
    onClose() {
      this.$store.dispatch("main/cancelAllRequests");
      this.$store.dispatch("orgs/init");
      this.dialogShow = false;
    },
    onClosed() {
      this.unobserveHeight();
      this.unobserveWidth();
    },
    handleClick(command) {
      switch (command) {
        case "import-org-from-xlsx": {
          this.importOrgFromXLS();
          break;
        }
        case "import-org-from-json": {
          this.importOrgFromJSON();
          break;
        }
        case "import-org-from-contract": {
          this.importOrgFromContract();
          break;
        }
        case "find-by-name": {
          this.searchBy = "name";
          break;
        }
        case "find-by-address": {
          this.searchBy = "address";
          break;
        }
        case "find-by-axioma-id": {
          this.searchBy = "axiomaId";
          break;
        }
        case "find-by-okpo": {
          this.searchBy = "okpo";
          break;
        }
        case "find-by-ua": {
          this.lang = "ua";
          break;
        }
        case "find-by-en": {
          this.lang = "en";
          break;
        }
        case "find-by-ru": {
          this.lang = "ru";
          break;
        }
        default:
          break;
      }
    },
    orgImportData(data) {
      var that = this;
      if (data.operation == "contract.org.import.xls") {
        if (data && data.data.length) {
          this.runQueryTreeReduce(data.data, data.options ? data.options : {});
        }
      }
    },
    onCommit() {
      //if (this.selectedOrgs && this.selectedOrgs.length) {
      this.onClose();
      this.$emit("find-commit", { target: this.target, result: this.selectedOrgs });
      //} else this.$message.error("Будь ласка, додайте один або декілька рядків в нижню таблицю!");
    },

    onAdd() {
      if (this.selectedRows1 && this.selectedRows1.length > 0) {
        this.runQueryTree();
      }
    },
    onDel() {
      var that = this;
      if (this.selectedRows2 && this.selectedRows2.length > 0) {
        var delIds = this.selectedRows2.map((item) => item.axiomaId);
        var additionalDelIds = [];
        this.selectedOrgs = this.selectedOrgs.filter((item) => {
          if (delIds.indexOf(item.axiomaId) != -1) return false;
          var intersect = item.path.filter((value) => delIds.includes(value[0]));
          if (intersect && intersect.length > 0) {
            additionalDelIds.push(item.axiomaId);
            return false;
          }
          return true;
        });
        this.gridOptions1.api.redrawRows({ rowNodes: delIds.concat(additionalDelIds).map((item) => that.gridOptions1.api.getRowNode(item)) });
      }
    },
    importOrgFromJSON() {
      bus.$emit("find.org.with.tree.forms.select.file.show", {
        title: $tt("Завантаження списку структур"),
        operation: "contract.structure.import",
        initModel: {},
      });
    },
    importOrgFromContract() {
      bus.$emit("select.contract.show", {
        title: $tt("Вибір договору для імпорту списку структур"),
      });
    },
    importOrgFromXLS() {
      bus.$emit("find.org.with.tree.forms.select.xls.file.show", {
        title: $tt("Завантаження списку структур"),
        operation: "contract.org.import.xls",
        initModel: { orgExists: [], lang: this.lang },
      });
    },
    commitSelectFile(data) {
      var that = this;
      if (data.operation == "contract.structure.import") {
        // import structures
        this.$store.dispatch("main/setGlobalLoading", $tt("Імпорт даних..."));
        that
          .readJSONFile(data.file)
          .finally(() => {
            bus.$emit("find.org.with.tree.forms.select.file.hide");
            this.$store.dispatch("main/setGlobalLoading", "");
          })
          .then((result) => {
            if (result && result.dataType && result.data) {
              that.selectedOrgs = result.data;
            } else {
              globalErrorMessage($tt("Імпорт даних"), $tt("Помилка в структурі JSON файла!"));
            }
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка імпорту!"), err);
          });
      }
    },
    onContractSelectData(data) {
      if (data && data.contract) {
        this.$store
          .dispatch("contracts/loadStructures", { id: data.contract._id })
          .then((data) => {
            this.selectedOrgs = data;
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка імпорту!"), err);
          });
      }
    },
    onFilterStringChange(value, timeout) {
      var that = this;
      if (value == "") {
        this.$store.dispatch("orgs/init");
        this.$store.dispatch("main/cancelAllRequests");
      } else {
        if (this.keyUpTimer) clearTimeout(this.keyUpTimer);
        this.keyUpTimer = setTimeout(
          () => {
            that.keyUpTimer = null;
            that.runQueryFilter(value);
          },
          timeout ? timeout : 600
        );
      }
    },
    runQueryFilter(value) {
      if (value && value.length >= 3) {
        this.$store.dispatch("main/cancelAllRequests").then(() => {
          this.$store
            .dispatch("orgs/get_list", {
              filter: value,
              //org_types: [2, 451],
              findType: "axioma",
              searchBy: this.searchBy,
              lang: this.lang,
            })
            .catch((err) => {
              this.$message.error(parseError(err.data.msg));
              this.$store.dispatch("orgs/init");
            })
            .finally((data) => {});
        });
      }
    },
    runQueryTree() {
      if (this.selectedRows1 && this.selectedRows1.length > 0) {
        try {
          var that = this;
          this.$store.dispatch("orgs/treeClear", []).then(() => {
            var queries = that.selectedRows1.map((row) => {
              return that.$store.dispatch("orgs/add_tree", { id: row.axiomaId, lang: this.lang, allowArchive: false });
            });
            Promise.all(queries)
              .then((res) => {
                var existIds = that.selectedOrgs.map((item) => item.axiomaId);
                var newOrgs = that.tree.filter((item) => existIds.indexOf(item.axiomaId) == -1);
                that.selectedOrgs = that.selectedOrgs.concat(newOrgs);
                that.gridOptions1.api.redrawRows({ rowNodes: that.selectedRows1.map((item) => that.gridOptions1.api.getRowNode(item.axiomaId)) });
                that.gridOptions1.api.deselectAll();
                //that.$store.dispatch("orgs/del", newOrgs);
              })
              .catch((err) => {
                globalErrorMessage($tt("Помилка!"), err);
              });
          });
        } catch (err) {
          globalErrorMessage($tt("Помилка!"), err);
        }
      }
    },
    applyImportedFieldsForOrg(newOrg, importOrg, fields) {
      fields.forEach((field) => {
        //if (importOrg[field]) newOrg[field] = importOrg[field]; // All of these orgs will have same categories as import orgs
        if (importOrg[field] && newOrg.axiomaId == importOrg.axiomaId) newOrg[field] = importOrg[field];
      });
    },
    runQueryTreeForItem(item, idx, total) {
      var that = this;
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("orgs/add_tree", { id: item.axiomaId, lang: this.lang, allowArchive: true })
          .then(() => {
            this.loadingWaitStatusText = $tt("Оброблено {idx} із {total}", { idx: idx + 1, total: total });
            var existIds = this.selectedOrgs.map((item) => item.axiomaId);
            var newOrgs = this.tree.filter((item) => existIds.indexOf(item.axiomaId) == -1);
            newOrgs.forEach((newOrg) => this.applyImportedFieldsForOrg(newOrg, item, ["srcCategory"]));
            this.selectedOrgs = that.selectedOrgs.concat(newOrgs);
            if (this.dialogShow) resolve();
            else reject($tt("Припинено користувачем"));
          })
          .catch((err) => {
            reject();
          });
      });
    },
    runQueryParentsTreeForItem(item, idx, total) {
      var that = this;
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("orgs/parentsClear")
          .then(() => {
            this.$store
              .dispatch("orgs/getParents", { id: item.axiomaId, lang: this.lang })
              .then(() => {
                this.loadingWaitStatusText = $tt("Оброблено {idx} із {total}", { idx: idx + 1, total: total });
                var existIds = this.selectedOrgs.map((item) => item.axiomaId);
                var newOrgs = this.parents.filter((item) => existIds.indexOf(item.axiomaId) == -1);
                newOrgs.forEach((newOrg) => this.applyImportedFieldsForOrg(newOrg, item, ["srcCategory"]));
                this.selectedOrgs = that.selectedOrgs.concat(newOrgs);
                if (this.dialogShow) resolve();
                else reject($tt("Припинено користувачем"));
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    runQueryTreeReduce(data, options) {
      if (!data) data = this.selectedRows1;
      if (!data || !data.length) return;
      var total = data.length;
      var idx = 0;
      data
        //.filter((item) => item.status)
        .reduce((prev, item) => {
          if (this.dialogShow) {
            if (options.importOrgWithChildren) return prev.then(() => this.runQueryTreeForItem(item, idx++, total));
            else return prev.then(() => this.runQueryParentsTreeForItem(item, idx++, total));
          } else return Promise.reject($tt("Припинено користувачем"));
        }, Promise.resolve())
        .finally(() => {
          this.loadingWaitStatusText = null;
        })
        .catch((err) => {
          globalErrorMessage("Помилка отриманя структур!", err);
        });
    },
    runQueryTreeSingle(singleItem) {
      if (singleItem) {
        try {
          var that = this;
          this.$store.dispatch("orgs/parentsClear").then(() => {
            that.$store
              .dispatch("orgs/getParents", { id: singleItem.axiomaId, lang: this.lang })
              .then((res) => {
                var existIds = that.selectedOrgs.map((item) => item.axiomaId);
                var newOrgs = that.parents.filter((item) => existIds.indexOf(item.axiomaId) == -1);
                that.selectedOrgs = that.selectedOrgs.concat(newOrgs);
                that.gridOptions1.api.redrawRows({ rowNodes: that.selectedOrgs.map((item) => that.gridOptions1.api.getRowNode(item.axiomaId)) });
              })
              .catch((err) => {
                globalErrorMessage($tt("Помилка!"), err);
              });
          });
        } catch (err) {
          globalErrorMessage($tt("Помилка!"), err);
        }
      }
    },
    runQueryTreeMulty() {
      if (this.selectedRows1 && this.selectedRows1.length > 0) {
        try {
          var that = this;
          this.$store.dispatch("orgs/parentsClear").then(() => {
            var queries = that.selectedRows1.map((row) => {
              return that.$store.dispatch("orgs/getParents", { id: row.axiomaId, lang: this.lang });
            });
            Promise.all(queries)
              .then((res) => {
                var existIds = that.selectedOrgs.map((item) => item.axiomaId);
                var newOrgs = that.parents.filter((item) => existIds.indexOf(item.axiomaId) == -1);
                that.selectedOrgs = that.selectedOrgs.concat(newOrgs);
                that.gridOptions1.api.redrawRows({ rowNodes: that.selectedRows1.map((item) => that.gridOptions1.api.getRowNode(item.axiomaId)) });
                that.gridOptions1.api.deselectAll();
              })
              .catch((err) => {
                globalErrorMessage($tt("Помилка!"), err);
              });
          });
        } catch (err) {
          globalErrorMessage($tt("Помилка!"), err);
        }
      }
    },
    onGridSelectionChanged() {
      var that = this;
      var rows = this.gridOptions1.api.getSelectedRows();
      var addIds = [];
      var delIds = [];
      if (!this.selectedRows1) {
        addIds = rows.map((item) => item.axiomaId);
      } else {
        var oldSelectedIds = this.selectedRows1.map((item) => item.axiomaId);
        var newSelectedIds = rows.map((item) => item.axiomaId);
        addIds = newSelectedIds.filter((x) => !oldSelectedIds.includes(x));
        delIds = oldSelectedIds.filter((x) => !newSelectedIds.includes(x));
      }
      if (addIds.length > 0) {
        this.orgs.forEach((item) => {
          var intersect = item.path.filter((value) => addIds.includes(value));
          if (intersect.length > 0) {
            var node = that.gridOptions1.api.getRowNode(item.axiomaId);
            if (node && !node.selected) node.setSelected(true);
          }
        });
      } else if (delIds.length > 0) {
        this.orgs.forEach((item) => {
          var intersect = item.path.filter((value) => delIds.includes(value));
          if (intersect.length > 0) {
            var node = that.gridOptions1.api.getRowNode(item.axiomaId);
            if (node && node.selected) node.setSelected(false);
          }
        });
      }
      this.selectedRows1 = rows;
    },
    onGridSelectionChanged2() {
      var that = this;
      var rows = this.gridOptions2.api.getSelectedRows();
      var addIds = [];
      var delIds = [];
      if (!this.selectedRows2) {
        addIds = rows.map((item) => item.axiomaId);
      } else {
        var oldSelectedIds = this.selectedRows2.map((item) => item.axiomaId);
        var newSelectedIds = rows.map((item) => item.axiomaId);
        addIds = newSelectedIds.filter((x) => !oldSelectedIds.includes(x));
        delIds = oldSelectedIds.filter((x) => !newSelectedIds.includes(x));
      }
      if (addIds.length > 0) {
        this.selectedOrgs.forEach((item) => {
          var intersect = item.path.filter((value) => addIds.includes(value[0]));
          if (intersect.length > 0) {
            var node = that.gridOptions2.api.getRowNode(item.axiomaId);
            if (node && !node.selected) node.setSelected(true);
          }
        });
      } else if (delIds.length > 0) {
        this.selectedOrgs.forEach((item) => {
          var intersect = item.path.filter((value) => delIds.includes(value[0]));
          if (intersect.length > 0) {
            var node = that.gridOptions2.api.getRowNode(item.axiomaId);
            if (node && node.selected) node.setSelected(false);
          }
        });
      }
      this.selectedRows2 = rows;
    },
    onGridDoubleClick() {
      this.onCommit();
    },
    onGridDoubleClick1() {
      this.onAdd();
    },
    onGridDoubleClick2() {
      // this.onDel();
    },
    onFormEnter() {
      this.onAdd();
    },
    onGridColumnsChange1() {
      var that = this;
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        var state = that.gridColumnApi1.getColumnState();
        clearTimeout(that.changeColumnsTimer);
        that.$store.dispatch("main/updateUserSettings", { findOrgWithTreeGridColumnState1: state });
      }, 500);
    },
    onGridColumnsChange2() {
      var that = this;
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        var state = that.gridColumnApi2.getColumnState();
        clearTimeout(that.changeColumnsTimer);
        that.$store.dispatch("main/updateUserSettings", { findOrgWithTreeGridColumnState2: state });
      }, 500);
    },
    gridLocale(key, defaultValue) {
      var localized = $tt("grid." + key);
      return localized ? localized : defaultValue;
    },
  },
};
</script>

<style lang="scss" scope>
#FindOrgWithTreeDialog .header2 {
  background: #fff;
  line-height: 32px;
  border-bottom: 1px solid #ccc !important;
  padding: 0 !important;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
}

#FindOrgWithTreeDialog .el-dialog--center .el-dialog__body {
  padding: 0px 16px 0 16px !important;
}

#FindOrgWithTreeDialog .el-loading-mask {
  top: 48px !important;
  /*bottom: -48px !important;*/
}

i.w0 {
  width: 10px !important;
}
span.active {
  font-weight: 700;
}
</style>
